import React from "react";
import Resources from "../components/Resources/Resources";

const ResourcesPage = () => {
    return (
        <div>
            <Resources />
        </div>
    );
};

export default ResourcesPage;
