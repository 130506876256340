import React from "react";
import Words from "../components/Words/Words";

const WordsPage = () => {
    return (
        <div>
            <Words />
        </div>
    );
};

export default WordsPage;
