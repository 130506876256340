import React from "react";
import Languages from "../components/Languages/Languages";

const LanguagesPage = () => {
    return (
        <div>
            <Languages />
        </div>
    );
};

export default LanguagesPage;
